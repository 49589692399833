import React from 'react';
import stylesD from './Slider1D.module.css'
import stylesM from './Slider1M.module.css'
import ImageGallery from 'react-image-gallery';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css'
import img1 from '../../Images/Slider1/37.png'
import img2 from '../../Images/Slider1/38.png'
import img3 from '../../Images/Slider1/39.png'
import img4 from '../../Images/Slider1/40.png'


const images = [
    {
      original: img1,
    },
    {
      original: img2,
    },{
        original: img3,
      },
      {
        original: img4,
      },]


function Slider1(props) {


if(props.type=='m'){return <>

<div className={stylesM.container}>
<ImageGallery 
    autoPlay={true}
    showBullets={false}
    showNav={false}
    showThumbnails={false}
    showFullscreenButton={false}
    showPlayButton={false}
    
    items={images} /> 
    
</div>



</>}


    return ( <>
    

<div className={stylesD.container}>
<ImageGallery 
    autoPlay={true}
    showBullets={false}
    showNav={false}
    showThumbnails={false}
    showFullscreenButton={false}
    showPlayButton={false}
    
    items={images} /> 
    
</div>

    
    
    
    </> );
}

export default Slider1;
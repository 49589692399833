import React from 'react';
import stylesM from './Block4M.module.css'
import stylesD from './Block4D.module.css'
import { Wecan } from '../../BasicComponents/BasicComponents';
import star from '../../../Images/star.png'
import {landingStrings} from '../../../../localization'
let s =landingStrings()

function Block4(props) {
    s.setLanguage(props.l)    

if(props.type=='m'){return <>

<div className={stylesM.container}>

<Wecan type={'m'} title1={s.wecan1_title1} title2={s.wecan1_title2} text={s.wecan1_text}/>
<Wecan type={'m'} title1={s.wecan2_title1} title2={s.wecan2_title2} text={s.wecan2_text}/>
<Wecan type={'m'} title1={s.wecan3_title1} title2={s.wecan3_title2} text={s.wecan3_text}/>
<Wecan type={'m'} title1={s.wecan4_title1} title2={s.wecan4_title2} text={s.wecan4_text}/>
<Wecan type={'m'} title1={s.wecan5_title1} title2={s.wecan5_title2} text={s.wecan5_text}/>
<Wecan type={'m'} title1={s.wecan6_title1} title2={s.wecan6_title2} text={s.wecan6_text}/>

<img width={'250px'} className={stylesM.star} src={star} alt="" />
<div className={stylesM.circle}></div>

    
</div>


</>}







    return ( <>
    <div className={stylesD.container}>
    <Wecan  title1={s.wecan1_title1} title2={s.wecan1_title2} text={s.wecan1_text}/>
<Wecan  title1={s.wecan2_title1} title2={s.wecan2_title2} text={s.wecan2_text}/>
<Wecan  title1={s.wecan3_title1} title2={s.wecan3_title2} text={s.wecan3_text}/>
<Wecan  title1={s.wecan4_title1} title2={s.wecan4_title2} text={s.wecan4_text}/>
<Wecan  title1={s.wecan5_title1} title2={s.wecan5_title2} text={s.wecan5_text}/>
<Wecan  title1={s.wecan6_title1} title2={s.wecan6_title2} text={s.wecan6_text}/>

<img className={stylesD.star} src={star} alt="" />
<div className={stylesD.circle}></div>

    </div>
    </> );
}

export default Block4;
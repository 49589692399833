import LocalizedStrings from 'react-localization'
import {reactLocalStorage} from 'reactjs-localstorage';






//Главная страница
export function landingStrings () {

    let strings =new LocalizedStrings({

//c ENG        
        en:{
services:'Services',
work_process:'Work-Process',
contact:'Contact',

getAfreeConsultation:'Get a Free consultation',
The_Result_Driven:'The result-driven',
SocialMediaMarketing:'Social Media Marketing',
Grow_sales_and_brand_awareness_on_social_media:'Grow sales and brand awareness on social media',
Agency:'Agency',

Social_Media:'Social Media',
Marketing:'Marketing',
years_of_experience:'years of experience',
successful_projects:'successful projects',
team_members:'team members',
Brands_that_trust_us_to_drive_more_revenue:'Brands that trust us to drive more revenue',
and_more_than_50:'and more than 50 companies using Social Media promotion tools…',


wecan1_title1:'Launch new products and stimulate a ',
wecan1_title2:'market demand',
wecan1_text:'Social media is a relatively inexpensive tool for promoting new businesses or startups that help to create or stimulate market demand',

wecan2_title1:'Increase reach and brand',
wecan2_title2:'awareness',
wecan2_text:'Social networks create a significant organic audience reach and increase brand recognition due to hashtags and feedback marks from customers, bloggers and other target communities',

wecan3_title1:'Develop and manage brand',
wecan3_title2:'reputation',
wecan3_text:'Social networks create a needful atmosphere, complement the brand concept and create the whiteness association of the company in the media environment',

wecan4_title1:'Increase ',
wecan4_title2:'customer trust',
wecan4_text:'Make friends with your customers, show behind-the-scenes moments of your business, and get to know the team, so your customer will be confident in your product',

wecan5_title1:'Display products',
wecan5_title2:'',
wecan5_text:'The toolkit of social networks helps to qualitatively and interestingly present your product in photos or video formats, make an engaging description and ask your customers opinions about it',

wecan6_title1:'Keep in touch with ',
wecan6_title2:'customers',
wecan6_text:'Receive feedback about your product, videos/photos in the process of use, quickly respond to negativity in the network and improve customer service',

Run_ads_and_get:'Run ads and get ',
sales:'sales',

the_swype_team:'The Swype team is working with your project: SMM specialist, PPC specialist(Ads setting specialist), content manager, designer, copywriter and influencer-marketing specialist.',

pt1:'1. Understanding your business',
pc1:'We will explore your product, target audience and market position, then generate ideas and proposals, and discuss them with you',

pt2:'2. Channel Selection & Planning',
pc2:'We will then choose the best social media platforms that will be most effective for your brand. We will create a promotion strategy and action plan',

pt3:'3. Create content and account management',
pc3:'We will create content deeply oriented on organic growth, write engaging texts, make inspiring videos, etc.',

pt4:'4. Promotion campaigns',
pc4:'We will set up Paid Social and Search advertising, design creatives and sales funnels. We cooperate with bloggers and opinion leaders.',

pt5:'5. In-Depth Reporting',
pc5:'Efficiency is the key to success! We will measure the effectiveness of our actions and prepare monthly reports.',


OurWorkingProcess:'Our Working Process',
We_help_founders:'We help founders, directors & marketing team implement Social media tools',

of_clients_increase:'of clients increase their return on ad',
views_across_our:'views across our videoad relatives',
in_trackable_revenue:'in trackable revenue across our client portfolio',


Hey_there:'Hey there! Let’s chat',
Get_in_touch:'Get in touch to see how we can help you',
name:'Name',
email:'Email',
phone:'Phone',
How_can_we_help:'How can we help?',
Services:'Services:',

datasent:'Success! The info sent.',
error1:'Fill out the form, please',
Run_ads_and_get_s:'Run ads and get sales ',




        },
//c UA
        ua:{

            services:'Послуги',
            work_process:'Як ми працюємо?',
            contact:'Контакти',
            getAfreeConsultation:'Отримати консультацію',

The_Result_Driven:'SMM-просування',
SocialMediaMarketing:'Маркетинг у соціальних',
Grow_sales_and_brand_awareness_on_social_media:'Збільшіть продажі та зростіть бренд у соцмережах',
Agency:'мережах',

Social_Media:'Маркетинг',
Marketing:' у соціальних',
years_of_experience:'років досвіду',
successful_projects:'успішних проектів',
team_members:'спеціалістів',
Brands_that_trust_us_to_drive_more_revenue:'Бренди, які довірили нам свої соцмережі',
and_more_than_50:'та ще більше 50 компаній, які користуються SMM - просуванням.',

wecan1_title1:'Запускайте нові продукти та формуйте',
wecan1_title2:'попит',
wecan1_text:'Соціальні медіа є відносно недорогим інструментом для просування нових компаній або стартапів. Вони допомагають створити або стимулювати ринковий попит',

wecan2_title1:'Збільшуйте охват та ',
wecan2_title2:'впізнаваність',
wecan2_text:'Соціальні мережі створюють значне органічне охоплення аудиторії та підвищують впізнаваність бренду завдяки хештегам і відміткам/відгукам від клієнтів, блогерів та інших цільових спільнот',

wecan3_title1:'Розвивайте бренд та керуйте ',
wecan3_title2:'репутацією',
wecan3_text:'Соціальні мережі створюють необхідну атмосферу, доповнюють концепцію бренду та асоціацію компанії в медіа-середовищі',

wecan4_title1:'Збільшуйте  ',
wecan4_title2:'довіру клієнтів',
wecan4_text:'Подружіться з клієнтами. Покажіть закулісні моменти вашого бізнесу та познайомте з командою, щоб ваш клієнт був точно впевнений у вашому продукті',

wecan5_title1:'Показуйте свій продукт якісно',
wecan5_title2:'',
wecan5_text:'Інструментарій соціальних мереж допомагає якісно та цікаво представити ваш продукт у форматі фотографій чи відео, зробити цікавий опис та запитати думку клієнтів про нього',

wecan6_title1:'Підтримуйте зв`язок  ',
wecan6_title2:'з клієнтами',
wecan6_text:'Отримуйте відгуки про ваш товар, відео/фото в процесі використання, швидко реагуйте на негатив в мережі та покращуйте обслуговування клієнтів',

Run_ads_and_get:'Запускайте рекламу та отримуйте ',
Run_ads_and_get_s:'Запускайте рекламу та отримуйте продажі ',

sales:'продажі',


the_swype_team:'Над вашим проектом працює команда Swype:SMM спеціаліст, PPC спеціаліст (спеціаліст з налаштування реклами), контент менеджер, дизайнер, копірайтер та інфлюенс-маркетолог.',


pt1:'1. Розуміння вашого бізнесу',
pc1:'Ми вивчимо ваш продукт, цільову аудиторію та позицію на ринку, потім створимо ідеї/пропозиції та обговоримо їх з вами.',

pt2:'2. Вибір каналів просування та планування',
pc2:'Потім ми виберемо найкращі платформи соціальних мереж, які будуть найбільш ефективними для вашого бренду. Створимо стратегію просування та план дій.',

pt3:'3. Створення контенту та аккаунт менеджмент',
pc3:'Ми будемо створювати контент, глибоко орієнтований на органічне зростання, писати захоплюючі тексти, знімати надихаючі відео тощо.',

pt4:'4. Платне просування у соцмережах',
pc4:'Ми налаштуємо платну таргетовану та пошукову рекламу, розробимо креативи та воронки продажів. Потім знайдемо для співпраці блогерів та лідерів думок.',

pt5:'5. Звітність',
pc5:'Ефективність - запорука успіху! Ми вимірюватимемо ефективність наших дій і складатимемо щомісячні звіти з подальшим планом розвитку. ',


OurWorkingProcess:'Наш робочий процес',

We_help_founders:'Ми допомагаємо власникам бізнесу та маркетологам впроваджувати інструментарій соціальних мереж',

of_clients_increase:'клієнтів підвищують рентабельність реклами',
views_across_our:'переглядів через наші відеореклами',
in_trackable_revenue:'відстежуваний дохід наших клієнтів',



Hey_there:'Давайте обговоримо',
Get_in_touch:'Зв’яжіться з нами, щоб дізнатися, як ми можемо вам допомогти',
name:'Ім’я',
email:'Email',
phone:'Телефон',
How_can_we_help:'Чим ми можемо допомогти? ',
Services:'Послуги',

datasent:'Данні відправленно!',
error1:'Заповніть будь-ласка контактні данні',



        }

        
    })
    


    return strings


}


//Change Language





import React from 'react';
import stylesM from './Block1M.module.css'
import stylesD from './Block1D.module.css'
import { Button } from '../../BasicComponents/BasicComponents';
import circles from '../../../Images/circles1.png'

import {landingStrings} from '../../../../localization'
let s =landingStrings()




function Block1(props) {

s.setLanguage(props.l)    

if(props.type=='m'){return <>

<div className={stylesM.container}>

<div className={`${stylesM.line1} ${stylesM.title}`}>{s.The_Result_Driven}</div>
<div className={`${stylesM.line2} ${stylesM.title2}`}>{s.Social_Media}</div>
<div className={`${stylesM.line4} ${stylesM.title2}`}>{s.Marketing}</div>
<div className={`${stylesM.line5} ${stylesM.title}`}>{s.Agency}</div>


<div className={stylesM.inner_container}>
<div className={stylesM.subtitle}>{s.Grow_sales_and_brand_awareness_on_social_media}</div>
<Button type={2} name={s.getAfreeConsultation} onClick={()=>{window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#contact"}}/>
</div>



<img className={stylesM.circles} src={circles} alt="" />
<div className={stylesM.effect}></div>


<div className={stylesM.circle}></div>

</div>

</>}





    return ( <>
    
    <div className={stylesD.container}>
        <div className={stylesD.line1}>{s.The_Result_Driven}</div>
        <div className={stylesD.line2}>{s.SocialMediaMarketing}</div>
        <div className={stylesD.line3}>{s.Grow_sales_and_brand_awareness_on_social_media}</div>
        
        <div className={stylesD.button_container}>
        <Button type={2} name={s.getAfreeConsultation} onClick={()=>{window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#contact"}}/>
        </div>

<div className={stylesD.effect}>
    <div className={stylesD.circle}></div>
    <img className={stylesD.circles} src={circles} alt="" />
    <div className={stylesD.agency}>{s.Agency}</div>
</div>

<div className={stylesD.effect2}>
    
</div>

    </div>
    
    </> );
}

export default Block1;
import React from 'react';
import { BlockTitle } from '../../BasicComponents/BasicComponents';
import stylesD from './Block3D.module.css'
import stylesM from './Block3M.module.css'
import brand from '../../../Images/Brands/brand.png'
import brand1 from '../../../Images/Brands/1.png'
import brand2 from '../../../Images/Brands/2.png'
import brand3 from '../../../Images/Brands/3.png'
import brand4 from '../../../Images/Brands/4.png'
import brand5 from '../../../Images/Brands/5.png'
import brand6 from '../../../Images/Brands/6.png'
import brand7 from '../../../Images/Brands/7.png'
import brand8 from '../../../Images/Brands/8.png'
import brand9 from '../../../Images/Brands/9.png'

import {landingStrings} from '../../../../localization'
let s =landingStrings()


function Block3(props) {

    s.setLanguage(props.l)    


if(props.type=='m'){return <>

<div className={stylesM.container}>
<BlockTitle type={'m'} title={s.Brands_that_trust_us_to_drive_more_revenue}/>

<div className={stylesM.brands}>
<img src={brand1} alt="" />
    <img width={'110px'} src={brand7} alt="" />
    <img width={'110px'} src={brand3} alt="" />
    <img width={'110px'} src={brand4} alt="" />
    <img width={'110px'} src={brand5} alt="" />
    <img width={'110px'} src={brand6} alt="" />
    <img width={'110px'} src={brand2} alt="" />
    <img width={'110px'} src={brand8} alt="" />

</div>


<div className={stylesM.info_container}>

<div className={stylesM.info}>{s.and_more_than_50}</div>
<div className={stylesM.circle}></div>

</div>



</div>



</>}



    
    return ( <>
    
    <div className={stylesD.container}>
    <BlockTitle  title={s.Brands_that_trust_us_to_drive_more_revenue}/>

<div className={stylesD.brands_container}>

<div className={stylesD.line1}>
    <img src={brand1} alt="" />
    <img src={brand7} alt="" />
    <img src={brand3} alt="" />
    <img src={brand4} alt="" />
    <img src={brand5} alt="" />

</div>

<div className={stylesD.line2}>
    <img src={brand6} alt="" />
    <img src={brand2} alt="" />
    <img src={brand8} alt="" />
    <img src={brand9} alt="" />
    
</div>


<div className={stylesD.line2}></div>

</div>

<div className={stylesD.info}>{s.and_more_than_50}</div>

<div className={stylesD.circle}></div>
    </div>
    </> );
}

export default Block3;
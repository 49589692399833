import React from 'react';
import stylesD from './BasicComponentsD.module.css'
import stylesM from './BasicComponentsM.module.css'


export function Button(props) {


if(props.type==1){return <>
        <button className={stylesD.button1} style={props.style} onClick={props.onClick}>{props.name}</button>

    </>}

if(props.type==2){return <>
    <button className={stylesD.button2} style={props.style} onClick={props.onClick}>{props.name}</button>

</>}
}

export function Result (props) {

if(props.type=='m'){return <>

<div style={{width:props.width}} className={stylesM.result_container}>

<div className={stylesM.result_title}>{props.title}</div>
<div className={stylesM.result_number}>{props.number}</div>

</div>



</>}





return (<>

<div className={stylesD.result_container}>

<div className={stylesD.result_title}>{props.title}</div>
<div className={stylesD.result_number}>{props.number}</div>

</div>

</>)
}

export function Wecan (props) {

if(props.type=='m'){return <>
<div className={stylesM.wecan_container}>
    <div className={stylesM.wecan_title1}>
       {props.title1} 
</div>
    <div className={stylesM.wecan_title2}>{props.title2}</div>
    <div className={stylesM.wecan_text}>
    {props.text}    
    </div>


</div>


</>}



return (<>
<div className={stylesD.wecan_container}>
    <div className={stylesD.wecan_title1}>
       {props.title1} 
</div>
    <div className={stylesD.wecan_title2}>{props.title2}</div>
    <div className={stylesD.wecan_text}>
    {props.text}    
    </div>


</div>
</>)
}

export function BlockTitle (props) {

if(props.type=='m'){return <>

<div style={props.style} className={stylesM.blockTitle}>{props.title}</div>

</>}


return (<>

<div style={props.style} className={stylesD.blockTitle}>{props.title}</div>
</>)
}

export function Service (props) {

if(props.type=='m'){return <>
<div className={stylesM.service_container}>
<div className={stylesM.service_number}>{props.number}</div>
<div className={stylesM.service_text}>
{props.text}
</div>

</div>


</>}


return (<>

<div className={stylesD.service_container}>
<div className={stylesD.service_number}>{props.number}</div>
<div className={stylesD.service_text}>
{props.text}
</div>

</div>

</>)
}

export function Input (props) {

if(props.type=='m'){return <>
<input id={props.id} value={props.value} onChange={props.onChange} placeholder={props.placeholder} className={stylesM.input} type="text" />

</>}


return (<>

<input id={props.id} value={props.value} onChange={props.onChange} placeholder={props.placeholder} className={stylesD.input} type="text" />

</>)
}

export function TextArea (props) {

if(props.type=='m'){return <>
<textarea placeholder={props.placeholder} className={stylesM.textarea} value={props.value} onChange={props.onChange} name="" id={props.id} cols="30" rows="10"></textarea>


</>}


return (<>
<textarea placeholder={props.placeholder} className={stylesD.textarea} value={props.value} onChange={props.onChange} name="" id={props.id} cols="30" rows="10"></textarea>
</>)
}
import React,{useEffect, useState} from 'react';
import { Button } from '../BasicComponents/BasicComponents';
import stylesD from './HeaderD.module.css'
import stylesM from './HeaderM.module.css'
import logo from '../../Images/logo.png'
import menuI from '../../Images/menu.png'

import {landingStrings} from '../../../localization'
let s =landingStrings()



//Вибір мови
function LSelector (props) {

let[type,setType]=useState('EN')


function handler () {

    if(type=='UA'){setType('EN');s.setLanguage('en');props.setLanguage('en') }else{setType('UA');s.setLanguage('ua');props.setLanguage('ua')}
}

    if(type=='UA'){return <div style={{cursor:'pointer'}} onClick={handler}>EN <span style={{fontWeight:'bold'}}>UA</span></div>}
    if(type=='EN'){return <div style={{cursor:'pointer'}} onClick={handler}><span style={{fontWeight:'bold'}}>EN</span> UA</div>}
}


export function Menu (props) {



    if(props.show){return (<>
    <div id={'menu'} className={stylesM.menu_container} >
    
    <div id={'menu'} className={stylesM.menu_link}>
        <a href={'#services'}  className={stylesM.title}>{s.services}</a>
    </div>
    
    <div id={'menu'} className={stylesM.menu_link}>
        <a href={'#process'}  className={stylesM.title}>{s.work_process}</a>
    </div>
    
    <div id={'menu'} style={{borderBottom:'none'}} className={stylesM.menu_link}>
        <a href={'#contact'}  className={stylesM.title}>{s.contact}</a>
    </div>
    
    
    </div>
    </>)}
    }


function Header(props) {

    let[menu,setMenu]=useState(false)

//Відкрити/закрити меню
function menuHandler () {
    
 menu?setMenu(false):setMenu(true)  
}

//Закриття меню
function handler (e) {
    if(menu==true&&e.target.id!='menu'){setMenu(false)}
}

   document.body.addEventListener('click',handler)

   
function handler2 (language) {
    props.setLanguage(language)
}


if(props.type=='m'){return <>

<div className={stylesM.container}>

<div className={stylesM.company}>SWYPE AGENCY</div>

<div className={stylesM.inner_container}>
<LSelector setLanguage={handler2}/>
<img id ={'menu'}className={stylesM.menu} src={menuI} alt="" onClick={menuHandler}/> 
</div>
<Menu show={menu} />
</div>

</>}





    return ( <>
    
    <div className={stylesD.container}>

<div className={stylesD.logo_container}>
    SWYPE AGENCY
</div>
<div className={stylesD.links}>
<a className={stylesD.link} href="#services">{s.services}</a>
<a className={stylesD.link} href="#process">{s.work_process}</a>
<a className={stylesD.link} href="#contact">{s.contact}</a>

</div>

<div className={stylesD.right_section}>
    <LSelector setLanguage={handler2} />
    <Button type={1} name={s.getAfreeConsultation} onClick={()=>{window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#contact"}}/>
</div>

    </div>
    
    
    </> );
}

export default Header;
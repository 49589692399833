import React from 'react';
import { Result } from '../../BasicComponents/BasicComponents';
import Slider1 from '../../Slider1/Slider1';
import stylesD from './Block2D.module.css'
import stylesM from './Block2M.module.css'
import {landingStrings} from '../../../../localization'
let s =landingStrings()


function Block2(props) {
    s.setLanguage(props.l)    


if(props.type=='m'){return <>

<div className={stylesM.container}>

<Slider1 type={'m'}/>


<div className={stylesM.results}>
<Result width={'131px'} type={'m'} title={s.years_of_experience} number={'6+'} />
<Result width={'131px'} type={'m'} title={s.successful_projects} number={'70+'} />
<Result width={'131px'} type={'m'} title={s.team_members} number={'11+'} />
<div className={stylesM.widget}>
<div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="1" data-height="40" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2042816"></div>
</div>

</div>


<div className={stylesM.effect1}></div>

</div>



</>}



    return ( <>
    
    <div className={stylesD.container}>

<div className={stylesD.left}>
    <Slider1 />
</div>
<div className={stylesD.right}>
<Result title={s.years_of_experience} number={'6+'} />
<Result title={s.successful_projects} number={'70+'} />
<div className={stylesD.widget}>
<div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="1" data-height="40" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2042816"></div>
</div>
<Result title={s.team_members} number={'11+'} />

</div>

</div>
    
    
    </> );
}

export default Block2;
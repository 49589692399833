import React from 'react';
import stylesD from './Slider2D.module.css'
import stylesM from './Slider2M.module.css'

import ImageGallery from 'react-image-gallery';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css'
import i1 from '../../Images/Slider2/13.png'
import i2 from '../../Images/Slider2/14.png'
import i3 from '../../Images/Slider2/15.png'
import i4 from '../../Images/Slider2/16.png'
import i5 from '../../Images/Slider2/17.png'


const images = [
    {
      original: i1,
    },
    {
      original: i2,
    },{
        original: i3,
      },
      {
        original: i4,
      },
      {
        original: i5,
      },
    
    
    ]


function Slider2(props) {

if(props.type=='m'){return <>

<div className={stylesM.container}>
<ImageGallery 
    autoPlay={true}
    showBullets={false}
    showNav={false}
    showThumbnails={false}
    showFullscreenButton={false}
    showPlayButton={false}
    
    items={images} /> 
    
</div>




</>}



    return ( <>
    

<div className={stylesD.container}>
<ImageGallery 
    autoPlay={true}
    showBullets={false}
    showNav={false}
    showThumbnails={false}
    showFullscreenButton={false}
    showPlayButton={false}
    
    items={images} /> 
    
</div>
 
    
    
    
    </> );
}

export default Slider2;
import React from 'react';
import stylesM from './Block5M.module.css'
import stylesD from './Block5D.module.css'
import { BlockTitle } from '../../BasicComponents/BasicComponents';
import Slider2 from '../../Slider2/Slider2';
import {landingStrings} from '../../../../localization'
let s =landingStrings()

function Block5(props) {
    s.setLanguage(props.l)    


if(props.type=='m'){return <>

<div className={stylesM.container}>
<div className={stylesM.title_container}>
    <BlockTitle type={'m'} title={s.Run_ads_and_get_s}/>
</div>

<div className={stylesM.slider_container}>
<Slider2 type={'m'}/>
</div>


    
</div>


</>}




    return ( <>
    
    <div className={stylesD.container}>

<div className={stylesD.title_container}>
    <BlockTitle title={s.Run_ads_and_get}/>
    <div className={stylesD.title2}>{s.sales}</div>
</div>

<Slider2/>


<div className={stylesD.circle}></div>
    </div>
    
    
    </> );
}

export default Block5;
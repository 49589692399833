import React, {useState, useEffect } from 'react';
import stylesD from './ProcessesD.module.css'
import stylesM from './ProcessesM.module.css'

import Faq from "react-faq-component";
import {landingStrings} from '../../../localization'
let s =landingStrings()







    let fstyles = {
        
        rowTitleTextSize:'24px',
        bgColor: 'transparent',
        titleTextColor: "#0D0224",
        rowTitleColor: "#000000",
        rowContentColor: '#000',
        arrowColor: "#000000",
        rowContentPaddingTop:'15px',
        rowContentPaddingBottom:'30px',

    };

    let fstylesM = {
        
        rowTitleTextSize:'18px',
        bgColor: 'transparent',
        titleTextColor: "#0D0224",
        rowTitleColor: "#000000",
        rowContentColor: '#000',
        arrowColor: "#000000",
        rowContentPaddingTop:'15px',
        rowContentPaddingBottom:'30px',

    };

    const config = {
        animate: true,
        arrowIcon: "+",
        expandIcon: "+",
        collapseIcon: "-",
        openOnload: 0,
        // tabFocus: true
    };



function Processes(props) {
    console.log(props.l);
    s.setLanguage(props.l)
  
    const data = {
        rows: [
            {
                title: s.pt1,
                content: s.pc1,
            },
            {
                title: s.pt2,
                content:s.pc2 ,
            },
            {
                title: s.pt3,
                content: s.pc3,
            },
            {
                title: s.pt4,
                content: s.pc4,
            },
            {
                title: s.pt5,
                content: s.pc5,
            },
            
        
        ]}



if(props.type=='m'){return <>

<div className={stylesM.container}>
    <Faq data={data} styles={fstylesM} config={config}/>

    </div>


</>}



    return ( <>

    <div className={stylesD.container}>
    <Faq data={data} styles={fstyles} config={config}/>

    </div>

    </> );
}

export default Processes;




import React from "react";
import stylesD from "./FooterD.module.css";
import stylesM from "./FooterM.module.css";
import insta from "../../Images/insta.png";

function Footer(props) {
	if (props.type == "m") {
		return (
			<>
				<div className={stylesM.container}>
					<div className={stylesM.email}>e-mail: info@swype.agency </div>
					{/* <div className={stylesM.phone}>phone: +38073 530 5003</div> */}
					<div
						className={stylesM.instagram_container}
						onClick={() => {
							window.open("https://instagram.com/swype.agency", "_blank");
						}}
					>
						<img className={stylesM.instagram} src={insta} alt="" />
					</div>

					<div className={stylesM.widget}></div>
					<div className={stylesM.company}>Swype Agency 2024</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className={stylesD.container}>
				<div className={stylesD.line}>
					<div className={stylesD.contacts}>
						<div className={stylesD.email}>e-mail: info@swype.agency </div>
						{/* <div className={stylesD.phone}>phone: +38073 530 5003</div> */}
					</div>

					<div
						className={stylesD.instagram}
						onClick={() => {
							window.open("https://instagram.com/swype.agency", "_blank");
						}}
					>
						<img src={insta} alt="" />
					</div>
				</div>

				<div className={stylesM.widget}></div>

				<div className={stylesD.company}>Swype Agency 2024</div>
			</div>
		</>
	);
}

export default Footer;

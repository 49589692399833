import React,{useState} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BlockTitle, Button, Input, TextArea } from '../../BasicComponents/BasicComponents';
import stylesD from './Block9D.module.css'
import stylesM from './Block9M.module.css'
import circles from '../../../Images/circles2.png'
import {landingStrings} from '../../../../localization'
let s =landingStrings()

function Block9(props) {
    s.setLanguage(props.l)    

    let[data,setData]=useState({name:'',phone:'',email:'', question:''})

function inputHandler (e) {
setData(ps=>({...ps,[e.target.id]:e.target.value}))
console.log(data);
}


async function sendData () {

    if(data.name==''||data.phone==''||data.email==''){
        toast.error(s.error1, {
            position: "bottom-right",
            autoClose: 1900,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
    
            return;
    }



    let response = await axios.post(`https://www.planguin.co/swype_leads`,data,{withCredentials:true})
    
    if(response.data==1){

setData({name:'',phone:'',email:'', question:''})


        toast.success(s.datasent, {
            position: "top-center",
            autoClose: 1900,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });



    }
}





if(props.type=='m'){return <>

<ToastContainer
position="top-center"
autoClose={1500}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>


<div className={stylesM.container} id={'contact'}>
<BlockTitle type={'m'} title={s.Hey_there}/>
<div className={stylesM.subtitle}>{s.Get_in_touch}</div>

<div className={stylesM.inputs}>
        <Input value={data.name} type={'m'} id={'name'} onChange={inputHandler} placeholder={s.name}/>
        <Input value={data.phone} type={'m'} id={'phone'} onChange={inputHandler} placeholder={'Email'}/>
        <Input value={data.email} type={'m'} id={'email'} onChange={inputHandler} placeholder={s.phone}/>
        <TextArea value={data.question} type={'m'} id={'question'} onChange={inputHandler} placeholder={s.How_can_we_help}/>
        <Button onClick={sendData} style={{width:'333px'}} type={2} name={s.getAfreeConsultation}/>


</div>

<div className={stylesM.circles_container}>
<img width={'220px'} className={stylesM.circles} src={circles} alt="" />

</div>


</div>


</>}


    return ( <>
    
    <ToastContainer
position="top-center"
autoClose={1500}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>



   <div id={'contact'} className={stylesD.container}>
    
    <div className={stylesD.left}>
<div className={stylesD.title}><BlockTitle title={s.Hey_there}/></div>
<div className={stylesD.subtitle}>{s.Get_in_touch}</div>
    </div>
    <div className={stylesD.right}>
        <Input value={data.name} id={'name'} onChange={inputHandler} placeholder={s.name}/>
        <Input value={data.phone} id={'phone'} onChange={inputHandler} placeholder={'Email'}/>
        <Input value={data.email} id={'email'} onChange={inputHandler} placeholder={s.phone}/>
        <TextArea value={data.question} id={'question'} onChange={inputHandler} placeholder={s.How_can_we_help}/>
        <Button onClick={sendData} type={2} name={s.getAfreeConsultation}/>
    </div>
    
    <img className={stylesD.circles} src={circles} alt="" />
    
    </div> 
    
    
    </> );
}

export default Block9;
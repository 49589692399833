import React from 'react';
import { BlockTitle, Result } from '../../BasicComponents/BasicComponents';
import stylesM from './Block8M.module.css'
import stylesD from './Block8D.module.css'
import {landingStrings} from '../../../../localization'
let s =landingStrings()


function Block8(props) {

    s.setLanguage(props.l)    




if(props.type=='m'){return <>

<div className={stylesM.container}>

    <div className={stylesM.title}>
    <BlockTitle type={'m'} title={s.We_help_founders}/>
    </div>
<div className={stylesM.results}>
    <Result type={'m'} title={s.of_clients_increase} number={'93%'} />
    <Result type={'m'} title={s.views_across_our} number={'150m'} />
    <Result type={'m'} title={s.in_trackable_revenue} number={'$5m'} />
    </div>


<div className={stylesM.effect1}></div>


</div>

</>}




    return ( <>
    
    <div className={stylesD.container}>

<div className={stylesD.title}>
<BlockTitle title={s.We_help_founders}/>
</div>


<div className={stylesD.line}>
    
    <Result title={s.of_clients_increase} number={'93%'} />
    <Result title={s.views_across_our} number={'150m'} />
    <Result title={s.in_trackable_revenue} number={'$5m'} />

</div>


    </div>
    
    </> );
}

export default Block8;
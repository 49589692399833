import React from 'react';
import { BlockTitle } from '../../BasicComponents/BasicComponents';
import Processes from '../../Processes/Processes';
import stylesD from './Block7D.module.css'
import stylesM from './Block7M.module.css'
import {landingStrings} from '../../../../localization'
let s =landingStrings()



function Block7(props) {
    s.setLanguage(props.l)    

if(props.type=='m'){return <>

<div id={'process'} className={stylesM.container}>

<BlockTitle type={'m'} title={s.OurWorkingProcess}/>
<Processes l={props.l} type={'m'}/>


</div>


</>}




    return ( <>
    
    <div id={'process'} className={stylesD.container}>
        <BlockTitle title={s.OurWorkingProcess}/>

<div className={stylesD.processes_container}>
<Processes l={props.l}/>
<div className={stylesD.circle}></div>
</div>



    </div>
    
    </> );
}

export default Block7;
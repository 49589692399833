import React, { useEffect, useState } from "react";
import stylesD from "./LandingD.module.css";
import { BlockTitle, Button, Input, Result, Service, TextArea, Wecan } from "./Components/BasicComponents/BasicComponents";
import Slider2 from "./Components/Slider2/Slider2";
import Slider1 from "./Components/Slider1/Slider1";
import Processes from "./Components/Processes/Processes";
import Header from "./Components/Header/Header";
import Block1 from "./Components/Blocks/Block1/Block1";
import Block2 from "./Components/Blocks/Block2/Block2";
import Block3 from "./Components/Blocks/Block3/Block3";
import Block4 from "./Components/Blocks/Block4/Block4";
import Block5 from "./Components/Blocks/Block5/Block5";
import Block6 from "./Components/Blocks/Block6/Block6";
import Block7 from "./Components/Blocks/Block7/Block7";
import Block8 from "./Components/Blocks/Block8/Block8";
import Block9 from "./Components/Blocks/Block9/Block9";
import Footer from "./Components/Footer/Footer";
import { Laptop } from "simpler-react-responsive";

function Landing() {
	let [language, setLanguage] = useState("en");

	function changeLanguage(language) {
		setLanguage(language);
	}

	useEffect(() => {}, [language]);

	if (window.innerWidth < 1024) {
		return (
			<>
				<div className={stylesD.containerM}>
					<Header type={"m"} setLanguage={changeLanguage} />
					<Block1 l={language} type={"m"} />
					<Block2 l={language} type={"m"} />
					<Block3 l={language} type={"m"} />
					<Block4 l={language} type={"m"} />
					<Block5 l={language} type={"m"} />
					<Block6 l={language} type={"m"} />
					<Block7 l={language} type={"m"} />
					<Block8 l={language} type={"m"} />
					<Block9 l={language} type={"m"} />
				</div>
				<Footer type={"m"} />
			</>
		);
	}

	return (
		<div>
			<div className={stylesD.container}>
				<Header setLanguage={changeLanguage} />
				<Block1 l={language} />
				<Block2 l={language} />
				<Block3 l={language} />
				<Block4 l={language} />
				<Block5 l={language} />
				<Block6 l={language} />
				<Block7 l={language} />
				<Block8 l={language} />
				<Block9 l={language} />
			</div>
			<Footer />
		</div>
	);
}

export default Landing;

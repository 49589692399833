import React from 'react';
import stylesM from './Block6M.module.css'
import stylesD from './Block6D.module.css'
import { BlockTitle, Service } from '../../BasicComponents/BasicComponents';
import star from '../../../Images/star.png'

import {landingStrings} from '../../../../localization'
let s =landingStrings()



function Block6(props) {
  s.setLanguage(props.l)    


if(props.type=='m'){return <>

<div id={'services'} className={stylesM.container}>
<BlockTitle type={'m'}  title={s.Services}/>

{props.l=='en'?engServicesM:uaServicesM}

<div className={stylesM.text}>
  {s.the_swype_team}
</div>


<div className={stylesM.effect}>
<div className={stylesM.circle}></div>
<img className={stylesM.star} src={star} alt="" />
</div>

<div className={stylesM.effect1}></div>

</div>




</>}





    return ( <>
    
  <div id={'services'} className={stylesD.container}>

<BlockTitle style={{paddingLeft:'50px'}} title={s.Services}/>

{props.l=='en'?engServices:uaServices}

<div className={stylesD.text}>
{s.the_swype_team}
</div>


<div className={stylesD.effect}>
<div className={stylesD.circle}></div>
<img className={stylesD.star} src={star} alt="" />
</div>



    </div>  
    
    </> );
}

export default Block6;



let engServices =<div className={stylesD.services}>
<div></div>
<Service number={'1.'} text={[`Social Media `,<strong>strategy</strong>,` design and implementation`]}/>
<Service number={'2.'} text={[`Social Media `,<strong>management</strong> ,` and content creation`]}/>
<Service number={'3.'} text={[<strong>Organic</strong>, ` brand `, <strong>promotion</strong>, ` (Video content strategy)`]}/>
<Service number={'4.'} text={[<strong>Paid Social</strong>, `  advertising (Facebook Ads, Instagram Ads, TikTok Ads`]}/>
<div></div>
<Service number={'5.'} text={[<strong> Paid Search</strong>, ` advertising (Google Ads, YouTube Ads)`]}/>
<div></div>
<Service number={'6.'} text={[<strong>Influence marketing</strong>]}/>

</div>

let uaServices = <div className={stylesD.services}>
<div></div>
<Service number={'1.'} text={[`Побудова та впровадження`,<strong>SMM стратегії</strong>]}/>
<Service number={'2.'} text={[<strong>Створення контенту</strong>, ` та ведення соцмереж`]}/>
<Service number={'3.'} text={[`Органічне просування бренду(`,<strong>відео-контент</strong>,`стратегії)`]}/>
<Service number={'4.'} text={[<strong>Таргетована реклама</strong>, `(Facebook Ads, Instagram Ads, TikTok Ads)`]}/>
<div></div>
<Service number={'5.'} text={[<strong>Пошукова та медійна реклама</strong>, `(Google Ads, YouTube Ads)`]}/>
<div></div>
<Service number={'6.'} text={[<strong>Робота з блогерами та цільовими спільнотами</strong>]}/>

</div>


let engServicesM =<div className={stylesM.services}>
<div></div>
<Service type={'m'} number={'1.'} text={[`Social Media `,<strong>strategy</strong>,` design and implementation`]}/>
<Service type={'m'} number={'2.'} text={[`Social Media `,<strong>management</strong> ,` and content creation`]}/>
<Service type={'m'} number={'3.'} text={[<strong>Organic</strong>, ` brand `, <strong>promotion</strong>, ` (Video content strategy)`]}/>
<Service type={'m'} number={'4.'} text={[<strong>Paid Social</strong>, `  advertising (Facebook Ads, Instagram Ads, TikTok Ads`]}/>
<div></div>
<Service type={'m'} number={'5.'} text={[<strong> Paid Search</strong>, ` advertising (Google Ads, YouTube Ads)`]}/>
<div></div>
<Service type={'m'} number={'6.'} text={[<strong>Influence marketing</strong>]}/>

</div>

let uaServicesM = <div className={stylesM.services}>
<div></div>
<Service type={'m'} number={'1.'} text={[`Побудова та впровадження`,<strong>SMM стратегії</strong>]}/>
<Service type={'m'} number={'2.'} text={[<strong>Створення контенту</strong>, ` та ведення соцмереж`]}/>
<Service type={'m'} number={'3.'} text={[`Органічне просування бренду(`,<strong>відео-контент</strong>,`стратегії)`]}/>
<Service type={'m'} number={'4.'} text={[<strong>Таргетована реклама</strong>, `(Facebook Ads, Instagram Ads, TikTok Ads)`]}/>
<div></div>
<Service type={'m'} number={'5.'} text={[<strong>Пошукова та медійна реклама</strong>, `(Google Ads, YouTube Ads)`]}/>
<div></div>
<Service type={'m'} number={'6.'} text={[<strong>Робота з блогерами та цільовими спільнотами</strong>]}/>

</div>